<template>
  <tr class="tr-antced-famil ">
    <td class="border-0 border-b border-solid border-gray-300 px-5 py-2">
      <label class="">{{familial.antecedent? familial.antecedent.name : familial.name}}</label>
    </td>
    <td class="border-0 border-b border-solid  border-gray-300 px-5 relative">
      <span>{{familial.moreInfo ? familial.moreInfo : '--'}}</span>
      <button  @click="removeAntecedFamil" class="rmv-line-antc-fam bg-transparent text-red-500 border-0  rounded-cu cursor-pointer"><i class="fas fa-trash"></i></button>
    </td>
    <td class="pl-4 py-2 border-0 " style="width:35px;">

    </td>
  </tr>
</template>

<script>
export default {
  props:['familial', 'patient'],
  data() {
    return {
      // antecedents:[]
    }
  },
  methods: {
    removeAntecedFamil(){
      this.$store
        .dispatch("healthbook/REMOVE_PATIENT_ANTECEDENT_FAMILIAUX", {
          patientId: this.patient._id,
          data:this.familial,
          onData:(data) => {
            this.$emit('removeAntecedFamil', this.familial)
          }
        })
    },

  }
};
</script>
<style lang="scss">
.rmv-line-antc-fam {
    display: none;
}
.tr-antced-famil:hover {
  .rmv-line-antc-fam {
    position: absolute;
    top: calc(50% - 7.6px) ;
    right:0px;
    display: inline-block;
  }
}
</style>
